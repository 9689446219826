<template>
	<InputBase
		type="text"
		v-bind="$props"
		@update:modelValue="$emit('update:modelValue', $event)"
		@enter="$emit('enter')"
		@clearSuggestions="$emit('clearSuggestions')"
		@optionSelected="$emit('suggestionSelected', $event)"
		@focus="$emit('focused')"
	>
		<template #before>
			<slot name="before"></slot>
		</template>
		<template #after>
			<slot name="after"></slot>
		</template>
	</InputBase>
</template>

<script>
import commonProps from "../common/commonProps";

export default defineNuxtComponent({
	name: "TnInputText",

	props: {
		...commonProps,
		iconLeft: { type: String, default: "" },
		iconRight: { type: String, default: "" },
	},
});
</script>
