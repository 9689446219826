<template>
	<label
		:labelFor="id"
		class="input"
		:class="wrapperClasses"
	>
		<div
			class="input__text"
			v-if="text"
			v-html="text"
		></div>
		<div class="field">
			<div class="horizontal">
				<slot name="before"></slot>
				<TnTextField
					style="width: 100%; position: relative"
					:name="name"
					:placeholder="placeholder"
					:disabled="disabled ? 'disabled' : null"
					:modelValue="inputValue"
					:autocomplete="(hasSuggestions && 'off') || autocomplete"
					:autofocus="autofocus"
					:maxlength="maxLength ? maxLength : null"
					:id="id"
					:type="type"
					:hidden="hidden"
					:iconLeft="iconLeft"
					:iconRight="iconRight"
					:dark="dark"
					:size="size"
					@blur="handleBlur"
					@focus="focusClear"
					@update:modelValue="handleInput"
					@keydown.tab="handleTabPress"
					@keydown.down="handleDownPress"
					@keydown.up="handleUpPress"
					@keydown.esc="handleEscPress"
					@keydown.enter="handleEnterPress"
					:loading="loading"
				>
				</TnTextField>
				<slot name="after"></slot>
				<div
					v-if="hasSuggestions"
					class="suggestions"
					:class="{ dark }"
				>
					<div
						class="suggestion"
						v-for="(suggestion, idx) in highlightedSuggestions"
						:key="`suggestion-${idx}`"
						tabindex="0"
						:class="focusedSuggestion === idx && 'focused'"
						@click="optionSelected(idx)"
						v-html="suggestion"
						data-cy="search-suggestion"
					></div>
				</div>
			</div>
		</div>
		<span
			class="hint"
			v-if="fieldHint && !hideDetails && !systemMessage"
			v-html="fieldHint"
		></span>
		<span
			class="system-message"
			v-if="!hideDetails && (systemMessage || !fieldHint)"
			>{{ systemMessage || "&nbsp;" }}</span
		>
	</label>
</template>

<script>
import commonProps from "./commonProps";

export default defineNuxtComponent({
	name: "InputBase",

	props: {
		...commonProps,
		iconLeft: { type: String, default: "" },
		iconRight: { type: String, default: "" },
		autofocus: { type: Boolean, default: false },
		size: { type: String, default: "m" },
	},

	data() {
		return {
			inputValue: this.modelValue || this.default,
			systemMessage: this.error || null,
			focusedSuggestion: -1,
			fieldHint: this.hint || (this.required && this.emptyError),
		};
	},

	mounted() {
		if (this.required) {
			if (this.inputValue === "" || typeof this.inputValue === "undefined") {
				this.addRequiredFormField();
			}
		}
	},

	computed: {
		validationIcon() {
			return this.modelValue || this.systemMessage;
		},
		valid() {
			return this.systemMessage === null;
		},
		hasSuggestions() {
			return this.suggestions && this.suggestions.length > 0;
		},
		highlightedSuggestions() {
			return this.suggestions.map((suggestion) => {
				try {
					return suggestion.replace(new RegExp(this.modelValue, "gi"), (match) => `<span>${match}</span>`);
				} catch (e) {
					console.error(e);
					return suggestion;
				}
			});
		},
		wrapperClasses() {
			return [this.hidden && "hidden", this.solo && "solo", this.valid === false && "invalid", this.dark && "dark"];
		},
	},

	methods: {
		setSystemMessage(msg) {
			this.addRequiredFormField();
			this.systemMessage = msg;
		},
		clearSystemMessage() {
			this.$store.commit("removeRequiredFormField", this._uid);
			this.systemMessage = null;
		},
		handleInput(event) {
			this.inputValue = event;
			if (!this.validateOnBlur) this.validate(this.inputValue);
			if (this.emitValid && this.systemMessage) return;
			this.$emit("update:modelValue", this.inputValue);
		},
		handleBlur() {
			if (this.validateOnBlur) {
				this.validate();
				this.$emit("update:modelValue", this.inputValue);
			}

			if (this.hasSuggestions) {
				setTimeout(() => {
					this.$emit("clearSuggestions");
				}, 200);
			}
		},
		focusClear() {
			this.$emit("focus");
			if (this.validateOnBlur) this.clearSystemMessage();
		},
		validate() {
			if ((!this.inputValue || this.inputValue.trim() === "") && this.required) {
				this.inputValue = "";
				this.setSystemMessage(this.emptyError);
			} else if (this.validation && !this.validation(this.inputValue)) {
				this.setSystemMessage(this.invalidError);
			} else if (this.maxLength && this.inputValue.trim().length > this.maxLength) {
				this.setSystemMessage(this.maxLengthExceededError);
			} else {
				this.clearSystemMessage();
			}
		},
		handleTabPress(e) {
			e.stopImmediatePropagation();
			if (this.hasSuggestions) {
				e.preventDefault();
				if (e.shiftKey) {
					this.handleUpPress(e);
				} else {
					this.handleDownPress(e);
				}
			}
		},
		handleDownPress(e) {
			e.stopImmediatePropagation();
			if (this.hasSuggestions) {
				if (this.focusedSuggestion < this.suggestions.length - 1) this.focusedSuggestion++;
				else this.focusedSuggestion = 0;
			}
		},
		handleUpPress(e) {
			e.stopImmediatePropagation();
			if (this.hasSuggestions) {
				if (this.focusedSuggestion > 0) this.focusedSuggestion--;
				else this.focusedSuggestion = this.suggestions.length - 1;
			}
		},
		optionSelected(idx) {
			this.inputValue = this.suggestions[idx !== undefined ? idx : this.focusedSuggestion];
			this.$emit("optionSelected", idx !== undefined ? idx : this.focusedSuggestion);
			this.focusedSuggestion = -1;
		},
		handleEnterPress(event) {
			if (this.hasSuggestions) {
				const selection = this.focusedSuggestion === -1 ? 0 : undefined;
				event.preventDefault();
				event.stopPropagation();
				this.optionSelected(selection);
			} else {
				this.$emit("enter");
			}
		},
		handleEscPress() {
			if (this.hasSuggestions) {
				this.focusedSuggestion = -1;
				this.$emit("clearSuggestions");
			}
		},
		addRequiredFormField() {
			if (this.type !== "button") {
				this.$store.commit("addRequiredFormField", { uid: this._uid, elementId: this.$el.id, validate: this.validate });
			}
		},
	},

	watch: {
		modelValue(newValue) {
			this.inputValue = newValue;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "sass:color";

.horizontal {
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;

	:deep(.input-wrapper) {
		width: 100%;
		position: relative;
	}

	.validation-icon {
		position: absolute;
		right: $spacing-s;
		align-self: center;
	}

	input {
		flex-grow: 1;
	}
}

input:focus + .loading-bar {
	background: $color-information-500-core;
	transition: background 0.2s;

	&.loading {
		&::after {
			background-color: $color-background-blue-light;
		}
	}
}

@keyframes loading-placeholder {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

.invalid {
	.loading-bar {
		background: $color-critical-500-core;
	}
}

.hidden {
	display: none;
}

.system-message {
	color: $color-critical-500-core;
}

.hint,
.system-message {
	display: inline-block;
	margin-top: 10px;

	@include font-text-s;

	:deep(strong) {
		font-weight: bold;
	}
}

.hint {
	color: $color-text;

	:deep(a) {
		text-decoration: none;
		color: $color-cta-default;

		&:hover {
			border-bottom: 1px solid $color-cta-default;
		}
	}
}

.dark .hint {
	color: #fff;

	:deep(a) {
		text-decoration: none;
		color: $color-twe-cta-default;

		&:hover {
			border-bottom: 1px solid $color-twe-cta-default;
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	box-shadow: 0 0 0 1000px #fff inset;
	transition: background-color 5000s ease-in-out 0s;
}

input {
	font-family: $font-family-telenor-ui;
	margin: 0;

	&[type="text"],
	&[type="number"],
	&[type="email"],
	&[type="tel"],
	&[type="url"],
	&[type="password"],
	&[type="date"] {
		width: 100%;
		padding: $spacing-s $spacing-none;
		border: none;
		caret-color: $color-information-500-core;
		color: $color-text;

		@include font-text-m;

		&:focus {
			outline: none;
		}
	}

	&[type="file"] {
		display: block;
		border-bottom: none !important;

		@include font-text-s;
	}
}

.has-bg input {
	position: relative;
	font-family: $font-family-telenor-ui;
	margin: 0;

	&[type="text"],
	&[type="email"],
	&[type="number"],
	&[type="tel"],
	&[type="url"],
	&[type="password"],
	&[type="date"] {
		padding: $spacing-s;
	}
}

.input {
	&__text {
		margin-bottom: $spacing-s;

		@include font-text-bold-s;
	}

	&__validation-icon {
		display: block;
		width: $spacing-m;
		height: $spacing-m;
		float: right;
		position: relative;
		top: -26px;

		& svg {
			width: 100%;
			height: 100%;
		}
	}

	&__valid {
		border-bottom: 1px solid $color-success-500-core !important;
	}
}

.disabled {
	color: $color-neutrals-200-tint;

	input {
		pointer-events: none;

		&[type="text"],
		&[type="email"],
		&[type="number"],
		&[type="tel"],
		&[type="url"],
		&[type="password"],
		&[type="date"] {
			border-bottom: 1px solid $color-neutrals-200-tint;
			caret-color: transparent;

			&:focus {
				border-bottom: 1px solid $color-neutrals-200-tint;
			}
		}
	}
}

.suggestions {
	--dropdown-background-color: #{$color-neutrals-white};
	--dropdown-background-color-hover: rgba(#{hexToRGB($color-cta-hover-background)}, 0.08);
	--dropdown-background-color-focus: rgba(#{hexToRGB($color-cta-focus)}, 1);
	--dropdown-color-focus: #{$color-neutrals-white};

	position: absolute;
	width: 100%;
	height: auto;
	overflow: auto;
	background: var(--dropdown-background-color);

	// top: 50px;
	border-radius: $border-radius-s;
	padding: 10px 0;
	z-index: 2;
	top: calc(100% + $spacing-2xs);

	@include shadow(l);

	@function hexToRGB($hex) {
		@return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb),
			color.channel($hex, "blue", $space: rgb);
	}

	&.dark {
		--dropdown-background-color: #37486b;
		--dropdown-background-color-hover: rgba(#{hexToRGB($color-cta-dark-hover-background)}, 0.08);
		--dropdown-background-color-focus: rgba(#{hexToRGB($color-cta-dark-focus)}, 1);
		--dropdown-color-focus: #{$color-primary-dark};
		.suggestion {
			color: #fff;
		}
	}

	.suggestion {
		padding: 10px 15px;
		font-weight: bold;

		&:hover {
			background: var(--dropdown-background-color-hover);
			cursor: pointer;
		}

		&.focused {
			// background: $color-background-grey;
			background: var(--dropdown-background-color-focus);
			color: var(--dropdown-color-focus);

			// TODO pending design
			// ::v-deed span {
			// background: var(--dropdown-background-color-focus);
			// }
		}

		// TODO pending design
		// ::v-deep span { have asked design about this.
		// background: rgba(253, 241, 146, 0.6);
		// background: var(--dropdown-background-color-focus);
		// }
	}
}
</style>
